<template>
  <form
    id="catForm"
    action
    data-vv-scope="category-form"
    @submit.prevent="validateForm('category-form')"
  >
    <v-row style="display: flex; justify-content: center">
      <v-col class="py-0" cols="12" md="10">
        <label style="display: block; height: 25px !important">{{
          $t("category.name")
        }}</label>
        <v-text-field
          outlined
          dense
          hide-details
          data-vv-validate-on="blur"
          v-model="category.category_name"
          v-validate="'required|min:2'"
          :error-messages="errors.collect('category-form.name')"
          color="secondary"
          data-vv-name="name"
        />
      </v-col>
      <v-col class="py-0" cols="12" md="10">
        <label>{{ $t("Marca") }}</label>
        <v-select
          outlined
          dense
          class="inputs"
          v-model="category.brand_id"
          :items="brand"
          item-text="brand_name"
          item-value="id"
          v-validate="'required'"
          :error_messages="errors.collect('category-form.brand')"
          vv_name="brand"
        />
      </v-col>
      <slot></slot>
    </v-row>
    <div
      class="pa-0 text-right"
      style="margin-right: -10px"
      v-if="buttons !== false"
    >
      <v-btn
        outlined
        @click="$emit('back')"
        style="height: 30px; width: 100px !important"
        class="mr-3"
        elevation="0"
        >{{ $t("cancel") }}</v-btn
      >
      <v-btn
        style="
          width: 100px !important;
          color: #363533;
          height: 30px;
          padding-top: 10px !important;
        "
        elevation="0"
        type="submit"
      >
        <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
        {{ $t("save", { name: $t("") }) }}
      </v-btn>
    </div>
  </form>
</template>
<script>
import constants from "@/constants";
import { mapActions } from "vuex";
export default {
  name: "CategorysForm",
  props: ["title", "category", "buttons"],
  mounted() {},
  data() {
    return {
      userProcessed: false,
      menu: false,
      category: [],
      brand: [],
    };
  },
  mounted() {
    this.fetchProductBrand();
  },
  methods: {
    ...mapActions("brand", ["getProductBrand"]),
    fetchProductBrand() {
      this.getProductBrand().then((data) => {
        this.brand = data;
        console.log(this.brand);
      });
    },
    async validateForm(scope) {
      this.errors.clear();
      let result = await this.$validator.validateAll(scope);
      if (result) {
        this.$emit("validated", this.category);
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="sass">
.inputs .v-input__slot
  margin-top: -10px

#catForm
  label
    display: block
    height: 35px !important
</style>
